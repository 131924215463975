<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">{{ driverId ? 'Manage' : 'Add' }} Driver</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<a @click='$router.go(-1)' href='#' class="btn btn-light mb-4 btn-sm">
				Go back
			</a>
			<div
				v-if="loading"
				class="d-flex justify-content-center align-items-center"
				>
				<div class="spinner-border" role="status"></div>
			</div>
			<div class="row" v-else>
				<div class="col-12 col-xl-8">
					<div class="card">
						<div class="card-header">
							<div>
								<h4 class="card-header-title">Add a New Driver</h4>
							</div>
						</div>
						<div class="card-body">
							<div
								class="alert alert-danger alert-dismissible fade show"
								role="alert"
								v-if="errorMessage"
								>
								{{ errorMessage }}
							</div>
							<form @submit.prevent="manageDriver">
								<div class="row mt-4">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> First name </label>
											<input
												v-model.trim="driver.fname"
												required
												type="text"
												class="form-control"
												/>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Last name </label>
											<input
												v-model.trim="driver.lname"
												required
												type="text"
												class="form-control"
												/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="mb-1"> Email address </label>
											<input
												v-model.trim="driver.email"
												required
												type="email"
												class="form-control"
												/>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label class="form-label"> Phone </label>
											<input
												v-model.trim="driver.phone"
												required
												type="text"
												class="form-control mb-3"
												placeholder="(___)___-____"
												inputmode="text"
												/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6" v-if="!driverId">
										<div class="form-group">
											<label class="mb-1"> Password </label>
											<input
												v-model.trim="driver.password"
												:required="!driverId"
												type="password"
												class="form-control"
												/>
										</div>
									</div>
								</div>
								<button
									type="submit"
									class="btn btn-primary mt-4"
									:disabled="processing"
									>
									{{
										processing
											? 'Processing...'
											: `${driverId ? 'Update' : 'Add'} Driver`
									}}
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: ['driverId'],
  data () {
    return {
      driver: {
        fname: '',
        lname: '',
        phone: '',
        email: '',
        password: ''
      },
      processing: false,
      errorProcessing: false,
      errorMessage: '',
      loading: false,
      errorLoading: false
    }
  },
  created () {
    if (this.driverId) {
      this.fetchDriver()
    }
  },
  methods: {
    fetchDriver () {
      this.loading = true
      this.errorLoading = false

      this.axios
        .get(`/v1/drivers/${this.driverId}`)
        .then((res) => {
          this.driver = { ...res.data }
        })
        .catch(() => {
          this.errorLoading = true
        })
        .finally(() => (this.loading = false))
    },
    manageDriver () {
      this.processing = true
      this.errorProcessing = false

      this.errorMessage = ''

      this.axios({
        method: this.driverId ? 'PATCH' : 'POST',
        url: this.driverId ? `/v1/drivers/${this.driverId}` : '/v1/drivers/',
        data: {
          fname: this.driver.fname,
          lname: this.driver.lname,
          phone: this.driver.phone,
          email: this.driver.email,
          password: this.driver.password
        }
      })
        .then((res) => {
          this.$swal({
            icon: 'success',
            title: `Driver ${this.driverId ? 'Updated' : 'Added'}`,
            text: `The driver "${this.driver.fname}" has been ${
              this.driverId ? 'updated' : 'added'
            } succesfully`,
            showCloseButton: true
          }).then(() => {
            this.$router.push({
              name: 'ShowDriver',
              params: { driverId: res.data.id }
            })
          })
        })
        .catch((e) => {
          this.errorProcessing = true

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg

          this.$swal({
            icon: 'error',
            text: 'An error occured, please try again',
            showCloseButton: true
          })
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>
